var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkObject(_vm.orderDetailPayment))?_c('div',{staticClass:"card-comp mb-0 drop-shadow"},[_c('div',{staticClass:"checkout-summary"},[_c('div',{staticClass:"checkout-summary__head flex-wrap"},[_c('div',{staticClass:"order-number"},[_c('div',[_vm._v("No. Order :")]),_vm._v(" "),_c('span',{staticClass:"font-bold-22"},[_vm._v("\n          "+_vm._s(_vm.orderDetailPayment.orders[0].reference.toUpperCase())+"\n        ")]),_vm._v(" "),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
            _vm.orderDetailPayment.orders[0].reference.toUpperCase()
          ),expression:"\n            orderDetailPayment.orders[0].reference.toUpperCase()\n          ",arg:"copy"}],staticClass:"copy",on:{"click":function($event){return _vm.showNotif()}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/static/img/icon/copy.svg"),"alt":"Copy"}}),_c('span',{staticClass:"copy-title d-none d-lg-inline-block"},[_vm._v("Salin")])])]),_vm._v(" "),_c('div',{staticClass:"order-total"},[_c('div',[_vm._v(" Jumlah yang harus dibayar : ")]),_vm._v(" "),_c('span',{staticClass:"font-bold-22"},[_vm._v("\n          "+_vm._s(_vm.formatRupiah(_vm.orderDetailPayment.orders[0].total_payment, "Rp"))+"\n        ")]),_vm._v(" "),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
            _vm.orderDetailPayment.orders[0].total_payment
          ),expression:"\n            orderDetailPayment.orders[0].total_payment\n          ",arg:"copy"}],staticClass:"copy",on:{"click":function($event){return _vm.showNotif()}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/static/img/icon/copy.svg"),"alt":"Copy"}}),_c('span',{staticClass:"copy-title d-none d-lg-inline-block"},[_vm._v("Salin")])])])]),_vm._v(" "),_c('div',{staticClass:"checkout-summary__instruction justify-content-between"},[_c('div',{staticClass:"bank-section-wrap mb-2"},[(_vm.orderDetailPayment.payment_method_type !== 'EDC')?[_c('span',[_vm._v("\n            Gunakan ATM / iBanking / mBanking / setor tunai untuk transfer ke\n            rekening berikut ini :\n          ")]),_vm._v(" "),_c('div',{staticClass:"bank-section row mx-0 px-0"},[_c('div',{staticClass:"bank-section__icon text-center",class:_vm.orderDetailPayment.payment_method_name === 'Mandiri' &&
                  _vm.orderDetailPayment.payment_method_type === 'Virtual Account'
                  ? 'col-lg-2'
                  : 'col-lg-4'},[_c('img',{staticClass:"img-bank",attrs:{"src":_vm.orderDetailPayment.orders[0].payment.files[0].value +
                    '.webp' || _vm.defaultImageProduct,"alt":"Logo Bank"},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),(
                _vm.orderDetailPayment.payment_method_name === 'Mandiri' &&
                  _vm.orderDetailPayment.payment_method_type === 'Virtual Account'
              )?_c('div',{staticClass:"col-lg-3"},[_c('span',{staticClass:"me-2 col-12 my-auto"},[_vm._v(" Kode Tagihan ")]),_vm._v(" "),_c('div',{staticClass:"d-flex col-12 my-auto"},[_c('span',{staticClass:"font-bold-22 "},[_vm._v("\n                  "+_vm._s(_vm.orderDetailPayment.midtrans_value.split(" ")[0])+"\n                ")]),_vm._v(" "),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                    _vm.orderDetailPayment.midtrans_value.split(' ')[0]
                  ),expression:"\n                    orderDetailPayment.midtrans_value.split(' ')[0]\n                  ",arg:"copy"}],staticClass:"copy my-auto",on:{"click":function($event){return _vm.showNotif()}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/static/img/icon/copy.svg"),"alt":"Copy"}}),_c('span',{staticClass:"copy-title d-none d-lg-inline-block"},[_vm._v("Salin")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('span',{staticClass:"me-2 col-12 my-auto"},[_vm._v("\n                "+_vm._s(_vm.orderDetailPayment.payment_method_type === "Bank Transfer"
                    ? "No. Rekening :"
                    : "No. Virtual Account")+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"d-flex col-12 my-auto"},[_c('span',{staticClass:"font-bold-22 "},[_vm._v("\n                  "+_vm._s(_vm.orderDetailPayment.payment_method_type === "Bank Transfer"
                      ? _vm.orderDetailPayment.account_number
                      : _vm.orderDetailPayment.payment_method_name === "Mandiri"
                        ? _vm.orderDetailPayment.midtrans_value.split(" ")[1]
                        : _vm.orderDetailPayment.midtrans_value)+"\n                ")]),_vm._v(" "),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                    _vm.orderDetailPayment.payment_method_type === 'Bank Transfer'
                      ? _vm.orderDetailPayment.account_number
                      : _vm.orderDetailPayment.payment_method_name === 'Mandiri'
                        ? _vm.orderDetailPayment.midtrans_value.split(' ')[1]
                        : _vm.orderDetailPayment.midtrans_value
                  ),expression:"\n                    orderDetailPayment.payment_method_type === 'Bank Transfer'\n                      ? orderDetailPayment.account_number\n                      : orderDetailPayment.payment_method_name === 'Mandiri'\n                        ? orderDetailPayment.midtrans_value.split(' ')[1]\n                        : orderDetailPayment.midtrans_value\n                  ",arg:"copy"}],staticClass:"copy my-auto",on:{"click":function($event){return _vm.showNotif()}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/static/img/icon/copy.svg"),"alt":"Copy"}}),_c('span',{staticClass:"copy-title d-none d-lg-inline-block"},[_vm._v("Salin")])])])]),_vm._v(" "),_c('div',{class:_vm.orderDetailPayment.payment_method_name === 'Mandiri' &&
                  _vm.orderDetailPayment.payment_method_type === 'Virtual Account'
                  ? 'col-lg-3'
                  : 'col-lg-4'},[_c('span',{staticClass:"col-12 my-auto"},[_vm._v(" Nama Rekening : ")]),_vm._v(" "),_c('div',{staticClass:"font-bold-22  col-12 my-auto"},[_vm._v("\n                "+_vm._s(_vm.orderDetailPayment.account_owner_name)+"\n              ")])])])]:[_c('div',{staticClass:"font-heading-2 mb-3"},[_vm._v("\n            PEMBAYARAN EDC\n          ")]),_vm._v(" "),_c('div',{staticClass:"section-edc row"},[_c('div',{staticClass:"col-12 d-flex flex-wrap"},[_c('img',{staticClass:"img-bank me-3 my-auto",attrs:{"src":_vm.orderDetailPayment.orders[0].payment.files[0].value +
                    '.webp' || _vm.defaultImageProduct,"alt":"Logo EDC"},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),_c('span',{staticClass:"font-website-small my-auto"},[_vm._v("\n                Silahkan melakukan pembayaran dikasir kami.\n              ")])])])],_vm._v(" "),(_vm.orderDetailPayment.payment_method_type !== 'Virtual Account')?_c('span',[_vm._v("\n          Dicek manual setelah mengirim Bukti pembayaran.\n        ")]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"instruction-button d-flex flex-wrap w-100 mt-lg-5 mt-3"},[_c('div',{staticClass:"col-12 text-center mb-3 mb-lg-0"},[_c('Button',{staticClass:"btn btn-main me-0 mb-3 mb-lg-0 me-lg-3",attrs:{"text":"Lihat Detail Pesanan"},nativeOn:{"click":function($event){return _vm.handleShowDetail.apply(null, arguments)}}}),_vm._v(" "),(
              (_vm.orderDetailPayment.payment_method_type === 'Bank Transfer' ||
                _vm.orderDetailPayment.payment_method_type === 'EDC') &&
                !_vm.orderDetailPayment.is_payment_proof_uploaded
            )?_c('Button',{staticClass:"btn btn-primary",attrs:{"text":"Kirim Bukti Transfer"},nativeOn:{"click":function($event){return _vm.handleConfirm.apply(null, arguments)}}}):_vm._e()],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }