import { render, staticRenderFns } from "./default.vue?vue&type=template&id=679ae7b9&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=679ae7b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679ae7b9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LittleBanner: require('/usr/src/app/components/base/LittleBanner.vue').default,Header: require('/usr/src/app/components/home/base/Header.vue').default,MainSidebar: require('/usr/src/app/components/home/base/MainSidebar.vue').default,Navbar: require('/usr/src/app/components/home/base/Navbar.vue').default,Footer: require('/usr/src/app/components/home/base/Footer.vue').default,PopupAddToCart: require('/usr/src/app/components/base/PopupAddToCart.vue').default,AlertAddToCart: require('/usr/src/app/components/base/AlertAddToCart.vue').default,AlertNotLogin: require('/usr/src/app/components/base/AlertNotLogin.vue').default,BottomNavigation: require('/usr/src/app/components/base/BottomNavigation.vue').default,PopupLocation: require('/usr/src/app/components/base/PopupLocation.vue').default})
