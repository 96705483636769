//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  mixins: [mixins],

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client ? window.innerWidth : 0,
      loading: true
    }
  },

  computed: {
    ...mapState('Contents', ['promotion']),
      normalDesktop () {
        return this.windowWidth >= 992
      }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.getExclusivePromotionBanners()
  },

  methods: {
    ...mapActions('Contents', ['getPromotion']),
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),

    async getExclusivePromotionBanners () {
      this.loading = true

      try {
        await this.getPromotion()
      } catch (error) {
        this.$message.error('Gagal mendapatkan data promo paninti')
      }

      this.loading = false
    }
  }
}
