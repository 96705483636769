import { render, staticRenderFns } from "./index.vue?vue&type=template&id=61051a2a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=61051a2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61051a2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,ListOrderReview: require('/usr/src/app/components/account/shop/order-review/ListOrderReview.vue').default,EmptyReview: require('/usr/src/app/components/account/shop/order-review/EmptyReview.vue').default})
