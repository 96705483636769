import { render, staticRenderFns } from "./index.vue?vue&type=template&id=505c5e4e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=505c5e4e&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505c5e4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,CardCommissionStat: require('/usr/src/app/components/account/affiliate/commission/CardCommissionStat.vue').default,ListCommissionHistoriesByMonth: require('/usr/src/app/components/account/affiliate/commission/ListCommissionHistoriesByMonth.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default,ModalCreateCommissionWithdrawSubmission: require('/usr/src/app/components/account/affiliate/commission/ModalCreateCommissionWithdrawSubmission.vue').default,ModalCreateBankAccount: require('/usr/src/app/components/base/ModalCreateBankAccount.vue').default,ModalDetailWithdrawCommission: require('/usr/src/app/components/account/affiliate/commission/ModalDetailWithdrawCommission.vue').default})
