//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { defaultSwal2Options } from '@/utils/helpers/swal2-helper';
import VClamp from 'vue-clamp'
import panstoreMixin from '@/mixins/index'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  data () {
    return {
      switchPrize: false,
      note: '',
      totalPrice: 0
    }
  },
  computed: {
    ...mapState('Cart', ['selectItem', 'cart']),
    ...mapState('Voucher', ['voucherUsed', 'checkVoucher']),
    ...mapGetters('Voucher', ['voucherUsedId', 'listVouchers']),
    ...mapState('Checkout', [
      'selectedCourier',
      'listItem',
      'selectedAddress',
      'noteShipping',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'pageTakeInStore',
      'onCheck',
      'usePoint',
      'enableShipping',
      'vouchers'
    ]),
    ...mapState('Checkout', {
      checkoutPaymentDetail: 'paymentDetail'
    }),
    ...mapGetters('Cart', [
      'getTotalPrice',
      'getTotalSelectedItem',
      'isEventSale',
      'getPaymentDetail'
    ]),
    ...mapGetters('Cart', {
      cartPaymentDetail: 'getPaymentDetail'
    }),
    ...mapState('User/address', ['userAddress']),
    paymentDetail () {
      return this.routeCheckout ? this.checkoutPaymentDetail : this.cartPaymentDetail
    },
    paymentDetailExist () {
      return !!Object.keys(this.paymentDetail).length
    },
    routeCheckout () {
      return this.$route.path === '/checkout'
    },
    checkPayload () {
      if (
        !this.pageTakeInStore &&
        Object.keys(this.listItem).length &&
        this.listItem.available.length &&
        Object.keys(this.selectedCourier).length &&
        Object.keys(this.selectedAddress).length &&
        (this.storeBranch.length || this.storeBranchPAS) &&
        (Object.keys(this.storeBranch[this.branchSelected]).length ||
          Object.keys(this.storeBranchPAS[this.branchSelected]).length) &&
        !this.onCheck
      ) {
        return false
      } else if (
        this.pageTakeInStore &&
        Object.keys(this.listItem).length &&
        this.listItem.available.length &&
        !this.onCheck
      ) {
        return false
      } else {
        return true
      }
    },
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    },
    listProduct () {
      const tempData = []
      if (Object.keys(this.listItem).length && this.$route.path === '/checkout') {
        this.listItem.available.map((avail) => {
          tempData.push(avail.id)
        })
      } else {
        this.selectItem.map((selectId) => {
          this.cart.map((c) => {
            c.cart_items.map((items) => {
              if (items.id === selectId) {
                tempData.push(items.id)
              }
            })
          })
        })
      }
      return tempData
    },
    enablePoint () {
      if (this.$route.path === '/cart') {
        return (
          Object.keys(this.user).length &&
          this.selectItem.length &&
          this.user.paninti_point
        )
      } else {
        return this.paymentDetail.paninti_point_owned
      }
    },
    userPoint () {
      if (this.$route.path === '/cart') {
        if (Object.keys(this.user).length) {
          return this.user.paninti_point
        }
      } else {
        return this.paymentDetail.paninti_point_owned
      }
      return 0
    },
    voucherDiscount () {
      const vouchers = this.$route.path === '/checkout' ? this.vouchers : this.voucherUsed
      if (vouchers) {
        const voucherDisc = vouchers.filter((used) => {
          return used.type === 'discount'
        })

        if (voucherDisc && voucherDisc.length) {
          return voucherDisc[0]
        }
      }
      return {}
    }
  },
  watch: {
    selectItem () {
      if (!this.selectItem.length) {
        this.setUsePoint(false)
        localStorage.removeItem('usePoint')
      }
    },
    async listProduct () {
      if (!this.routeCheckout) {
        await this.checkAvailibilities(
          this.listProduct,
          this.$route.path === '/cart' ? null : this.getDataBranch()[this.branchSelected].id,
          this.selectedAddress.id,
          this.selectedCourier.code,
          this.selectedCourier.service,
          this.pageTakeInStore,
          this.voucherUsedId,
          this.usePoint
        )
      }
    }
  },
  async mounted () {
    if (localStorage.note) {
      const note = JSON.parse(localStorage.note)
      this.note = note.note
      this.switchPrize = note.switchPrize
    }
    if (localStorage.cartPrice) {
      this.totalPrice = localStorage.cartPrice
    }
    if (localStorage.usePoint) {
      this.setUsePoint(true)
    }
    if (!(Object.keys(this.user).length && this.user.paninti_point)) {
      this.setUsePoint(false)
    }
    if (
      this.$route.path === '/cart' &&
      this.usePoint &&
      !this.selectItem.length
    ) {
      this.setUsePoint(false)
      localStorage.removeItem('usePoint')
    }
    if (localStorage.cartItem && !this.selectItem.length) {
      this.setSelectedItem(JSON.parse(localStorage.cartItem))
    }

    if (!this.routeCheckout) {
      await this.checkAvailibilities(
        this.listProduct,
        this.$route.path === '/cart' ? null : this.getDataBranch()[this.branchSelected].id,
        this.selectedAddress.id,
        this.selectedCourier.code,
        this.selectedCourier.service,
        this.pageTakeInStore,
        this.voucherUsedId,
        this.usePoint
      )
    }
  },
  methods: {
    ...mapActions('Voucher', ['getVouchersByType']),
    ...mapMutations('Voucher', [
      'setVoucherUsed',
      'setPage',
      'setTotalPage',
      'setVouchers',
      'clearVouchersByType'
    ]),
    ...mapMutations('Checkout', ['setUsePoint']),
    ...mapMutations('Cart', ['setSelectedItem']),
    async handleOpenVoucher () {
      if (this.selectItem.length) {
        this.clearVouchersByType('vouchersDiscount')
        this.clearVouchersByType('vouchersShipping')
        await this.getListVouchers('discount', '1')
        await this.getListVouchers('free-shipping-cost', '1')

        this.$refs.openVoucher.click()
      }
    },
    getVoucherCode () {
      if (this.voucherUsed.length) {
        const codes = this.voucherUsed.map((used) => {
          return used.code
        })

        return codes.join(',')
      }
      return ''
    },
    setItemLocal () {
      if (this.$auth.loggedIn) {
        this.handleSetData()
        if (this.$route.name === 'cart') {
          if (this.userAddress.length || this.isEventSale) {
            return this.$router.push('/checkout')
          } else {
            this.$Swal
              .fire({
                title: 'Anda belum memiliki alamat',
                text: 'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Tambah alamat'
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$refs.buttonCreateAddress.click()
                } else {
                  this.clearDataCart()
                }
              })
          }
        } else if (!this.enableShipping) {
          this.$Swal
            .fire({
              ...defaultSwal2Options(),
              title: 'Pemberitahuan',
              text: 'Produk tersebut hanya dapat diambil di toko',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: true,
              customClass: {
                cancelButton: 'paninti-btn paninti-btn-large paninti-btn-error-outline'
              },
              cancelButtonColor: '#ea2828',
              confirmButtonText: 'Ya, Lanjutkan Belanja',
              cancelButtonText: 'Batal'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.buttonShowModalPaymentMethod.click()
              }
            })
        } else {
          this.$refs.buttonShowModalPaymentMethod.click()
        }
      } else {
        this.$refs.btnShowLogin.click()
      }
    },
    handleSetData () {
      localStorage.cartItem = JSON.stringify(this.selectItem)
      localStorage.cartPrice = this.getTotalPrice
      if (this.switchPrize) {
        localStorage.note = JSON.stringify({
          switchPrize: this.switchPrize,
          note: this.note
        })
      } else {
        localStorage.removeItem('note')
      }
    },
    StoreProductPrice (price) {
      let totalDisc = 0

      if (this.voucherUsed.length && this.getPaymentDetail && Object.keys(this.getPaymentDetail).length) {
        totalDisc = this.getPaymentDetail.total_discount_voucher

        return this.formatRupiah((price - totalDisc).toString(), 'Rp')
      }

      return this.formatRupiah(price.toString(),
        'Rp. '
      )
    },
    countProduct (product) {
      let qty = 0
      product.map((p) => {
        qty += p.quantity
      })
      return qty
    },
    async getListVouchers (type = 'discount', page = '1') {
      try {
        await this.getVouchersByType({
          type,
          data: {
            cart_id: this.listProduct,
            voucher_id_used: [...this.voucherUsedId],
            paginated: true,
            only_available: false,
            page,
            per_page: 2
          },
          params: {
            page,
            per_page: 2
          }
        })
      } catch (error) {
        this.setVouchers({
          page: 1,
          data: []
        })
        this.setTotalPage(0)
        console.log(error)
      }
    },
    async handleSwitchPoint () {
      this.setUsePoint(!this.usePoint)
      await this.checkAvailibilities(
        this.listProduct,
        this.$route.path === '/cart' ? null : this.getDataBranch()[this.branchSelected].id,
        this.selectedAddress.id,
        this.selectedCourier.code,
        this.selectedCourier.service,
        this.pageTakeInStore,
        this.voucherUsedId,
        this.usePoint
      )

      if (this.usePoint) {
        localStorage.setItem('usePoint', this.usePoint)
      } else {
        localStorage.removeItem('usePoint')
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
