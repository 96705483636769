//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  name: 'DashboardAffiliate',
  mixins: [panstoreMixin],
  props: {
    slider: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      affiliateStats: [
        {
          id: 1,
          title: 'Total Produk',
          subtitle: 'Produk yang aktif',
          amount: 0,
          percentage: '-',
          price: false,
          trendStatus: 'constant',
          footerType: 'custom',
          infoText: ''
        },
        {
          id: 2,
          title: 'Total Klik',
          subtitle: 'Sepanjang Waktu',
          amount: 0,
          percentage: '-',
          price: false,
          trendStatus: 'constant',
          footerType: '',
          infoText: ''
        },
        {
          id: 3,
          title: 'Total Pesanan',
          subtitle: 'Sepanjang Waktu',
          amount: 0,
          percentage: '-',
          price: false,
          trendStatus: 'constant',
          footerType: '',
          infoText: ''
        },
        {
          id: 4,
          title: 'Total Komisi',
          subtitle: 'Sepanjang Waktu',
          amount: 0,
          percentage: '-',
          price: true,
          trendStatus: 'constant',
          footerType: '',
          infoText: ''
        }
      ],
      filter: 'all_time',
      filterOptions: [
        {
          label: 'Sepanjang waktu',
          value: 'all_time'
        },
        {
          label: 'Hari ini',
          value: 'today'
        },
        {
          label: 'Minggu ini',
          value: 'this_week'
        },
        {
          label: 'Bulan ini',
          value: 'this_month'
        }
      ],
      loading: true
    };
  },
  computed: {
    ...mapState('Affiliate', ['summaryCount']),
    ...mapState('Base', ['csPhone']),
    alertCheckMessage () {
      let alertObj = {}

      const username = this.$auth.user.username
      let name = this.$auth.user.first_name
      name += this.$auth.user.last_name ? ` ${this.$auth.user.last_name}` : ''

      if (!this.$auth.user.affiliate.is_active) {
        alertObj = Object.assign({
          message: 'Program Affiliate by Paninti di' +
            ' akunmu sudah tidak aktif, untuk mengaktifkannya ' +
            'kembali mohon untuk menghubungi CS kami',
          redirect: `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai akun ${username}-${name} saya yang afiliasinya sudah tidak aktif`
        })
      } else if (this.$auth.user.affiliate.is_expired) {
        alertObj = Object.assign({
          message: 'Program Affiliate by Paninti di' +
            ' akunmu sudah kadaluarsa, untuk mengaktifkannya ' +
            'kembali mohon untuk menghubungi CS kami',
          redirect: `https://api.whatsapp.com/send?phone=${this.csPhone}&text=Saya ingin bertanya mengenai akun ${username}-${name} saya yang afiliasinya sudah kadaluarsa`
        })
      }

      return alertObj
    }
  },
  async created () {
    await this.fetchSummaryCount();
  },
  methods: {
    ...mapActions('Affiliate', ['getSummaryCount']),
    async fetchSummaryCount () {
      this.loading = true;
      const payload = {
          params: {
            period: this.filter || 'all_time'
          }
      };
      try {
          const resp = await this.getSummaryCount(payload)
          if (resp.data.status_code === 200) {
            this.affiliateStats[0] = {
              ...this.affiliateStats[0],
              amount: this.summaryCount.product_statistic.total_product || 0,
              infoText: 'Semua produk afiliasi',
              footerType: 'custom'
            }
            this.affiliateStats[1] = {
              ...this.affiliateStats[1],
              amount: this.summaryCount.click_statistic.total_click || 0,
              percentage: this.summaryCount.click_statistic.trend_percentage,
              trendStatus: this.summaryCount.click_statistic.trend_status || 'constant',
              subtitle: this.summaryCount.period.label,
              infoText: `Dari ${this.summaryCount.period.previous_label || ''}`,
              footerType: ''
            }
            this.affiliateStats[2] = {
              ...this.affiliateStats[2],
              amount: this.summaryCount.order_statistic.total_order || 0,
              percentage: this.summaryCount.order_statistic.trend_percentage,
              trendStatus: this.summaryCount.order_statistic.trend_status || 'constant',
              subtitle: this.summaryCount.period.label,
              infoText: `Dari ${this.summaryCount.period.previous_label || ''}`,
              footerType: ''
            }
            this.affiliateStats[3] = {
              ...this.affiliateStats[3],
              amount: this.summaryCount.commission_statistic.total_commission || 0,
              percentage: this.summaryCount.commission_statistic.trend_percentage,
              trendStatus: this.summaryCount.commission_statistic.trend_status || 'constant',
              subtitle: this.summaryCount.period.label,
              infoText: `Dari ${this.summaryCount.period.previous_label || ''}`,
              footerType: ''
            }
          }
      } catch (error) {
          console.log(error);
      } finally {
          this.loading = false;
      }
    },
    handleChangeFilter () {
     this.fetchSummaryCount()
    }
  }
}
