//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

import { fileVariants, getDefaultImageFromFiles, getDefaultImgProductOnError } from '@/utils/helpers/file-helper'
import { formatRupiah, generateRandomString } from '@/utils/helpers/string-helper'
import { getShareLinkSocialMediaUrl, socialMedias } from '@/utils/helpers/social-media-helper'
import { productImageNames } from '@/utils/helpers/product-helper'

export default {
  components: { VClamp },

  props: {
    product: {
      type: Object,
      default: () => ({
        name: '',
        price: '0',
        discounted_price: '0',
        share_link: '',
        share_text: '',
        files: [],
        is_affiliated: false,
        store: {
          name: ''
        }
      })
    }
  },

  data () {
    return {
      //
    }
  },

  computed: {
    socialMedias () {
      return socialMedias
    },

    productImageNames () {
      return productImageNames
    },

    fileVariants () {
      return fileVariants
    },

    productStore () {
      return this.product ? this.product.store : null
    },

    productShareText () {
      return this.product ? String(this.product.share_text).trim() : ''
    }
  },

  methods: {
    generateRandomString,
    formatRupiah,
    getDefaultImgProductOnError,
    getDefaultImageFromFiles,

    openCopyLinkSuccessAlert () {
      this.$message({
        message: 'Tautan berhasil disalin ke clipboard',
        type: 'success'
      })
    },

    openWhatsappShareLink () {
      window.open(getShareLinkSocialMediaUrl(socialMedias.whatsapp.code, this.productShareText), '_blank', 'width=500,height=400')
    }
  }
}
