var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-navigation bottom-navigation-container"},[_c('div',{staticClass:"navigation-wrapper"},[_vm._l((_vm.navigationData),function(item,index){return [_c('a',{key:index,staticClass:"navigation-item",class:{ active: _vm.checkMenuActive(index) },attrs:{"href":item.link}},[(
            item.type === 'normal' ||
              item.type === 'cart' ||
              item.type === 'wishlist'
          )?[_c('img',{staticClass:"navigation-icon",class:{ fblue: _vm.checkMenuActive(index) },attrs:{"src":item.icon,"alt":("Icon " + (item.text))}}),_vm._v(" "),_c('div',{staticClass:"navigation-text"},[_vm._v(_vm._s(item.text))]),_vm._v(" "),(item.type === 'cart' && _vm.getTotalQtyCart)?[(_vm.$route.name !== 'cart' && _vm.$route.name !== 'checkout')?_c('div',{staticClass:"badge-counter"},[_vm._v("\n              "+_vm._s(_vm.getTotalQtyCart || 0)+"\n            ")]):_vm._e()]:_vm._e()]:(item.type === 'account')?[(!_vm.$auth.loggedIn)?[_c('img',{staticClass:"navigation-icon",attrs:{"src":item.icon,"alt":("Icon " + (item.text))}})]:[(_vm.$auth.user)?_c('img',{staticClass:"navigation-avatar",attrs:{"alt":"Avatar","src":_vm.user.files.length
                ? _vm.getImageOriginal(_vm.user.files, 'avatar')
                : _vm.getDefaultAvatar(
                  _vm.user.detail ? _vm.user.detail.gender : 'male'
                )},on:{"error":function () {
                            var args = [], len = arguments.length;
                            while ( len-- ) args[ len ] = arguments[ len ];

                            return _vm.getDefaultImgAvatar.apply(
                    void 0, args.concat( [_vm.user.detail ? _vm.user.detail.gender : 'male'] )
                  );
}}}):_vm._e(),_vm._v(" "),(item.accountNotif)?_c('div',{staticClass:"badge-counter"},[_vm._v("\n              "+_vm._s(item.accountNotif)+"\n            ")]):_vm._e()],_vm._v(" "),_c('div',{staticClass:"navigation-text"},[_vm._v(_vm._s(item.text))])]:_vm._e()],2)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }