//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

import { waitFor } from '@/services/axiosConfig'

import { getResponseErrorMessage, isResponseInternalServerError } from '@/utils/helpers/response-helper'
import {
  affiliateCommissionBalanceTypes, affiliateCommissionStatFooterTypes,
  affiliateCommissionStatTrendStatuses, commissionFilterPeriods,
  minimumWithdrawalAmount
} from '@/utils/helpers/affiliate-commission-helper'
import {
  assignResponseToDataModelData, assignResponseToDataModelDataNotPaginated, defaultNotPaginationAttributes,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { accountTypes } from '@/utils/helpers/account-helper'
import { homePagePaths } from '@/utils/helpers/page-helper'
import { toastTypes } from '@/utils/helpers/toast-helper'
import { defaultSwal2Options } from '@/utils/helpers/swal2-helper'

export default {
  layout: 'account',
  middleware: ['user', 'affiliate'],

  data () {
    return {
      breadCrumb: [
        {
          path: homePagePaths.account
        },
        {
          name: 'Afiliasi',
          path: '/account/affiliate/'
        },
        {
          name: 'Komisi'
        }
      ],

      // Commission Stats
      commissionFilter: {
        periodDate: commissionFilterPeriods.thisMonth.value
      },

      getCommissionSummaryData: {
        ...defaultNotPaginationAttributes(),
        commissionPercentage: -1,
        commissionStats: {
          balance: {
            code: 'balance',
            title: 'Saldo',
            subtitle: 'Belum ditarik',
            amount: 0,
            percentage: '-',
            price: true,
            infoText: '',
            trendStatus: affiliateCommissionStatTrendStatuses.constant.value,
            footerType: affiliateCommissionStatFooterTypes.custom.value
          },
          orderCompleted: {
            code: 'order-completed',
            title: 'Pesanan Selesai',
            subtitle: 'Januari',
            amount: 0,
            percentage: '-',
            price: false,
            infoText: '',
            trendStatus: affiliateCommissionStatTrendStatuses.constant.value,
            footerType: affiliateCommissionStatFooterTypes.general.value
          },
          totalCommission: {
            code: 'total-commission',
            title: 'Total Komisi',
            subtitle: 'Januari',
            amount: 0,
            percentage: '-',
            price: true,
            infoText: '',
            trendStatus: affiliateCommissionStatTrendStatuses.constant.value,
            footerType: affiliateCommissionStatFooterTypes.general.value
          },
          withdrawalPending: {
            code: 'withdrawal-pending',
            title: 'Penarikan Diproses',
            subtitle: '',
            amount: 0,
            percentage: '-',
            price: true,
            infoText: '',
            trendStatus: '',
            footerType: affiliateCommissionStatFooterTypes.custom.value
          }
        }
      },

      // Commission Withdrawal
      getMasterBanksData: {
        ...defaultNotPaginationAttributes()
      },

      getUserBankAccountsData: {
        ...defaultNotPaginationAttributes(),
        params: {
          type: accountTypes.bank
        }
      },

      createUserBankAccountData: {
        loading: false
      },

      createCommissionWithdrawalData: {
        loading: false
      },

      // List Commission Histories
      commissionHistoryFilter: {
        balanceType: affiliateCommissionBalanceTypes.all.value
      },

      getCommissionHistoriesData: {
        ...defaultPaginationAttributes()
      },

      commissionHistoryDetail: {
        loading: false,
        balanceType: null,
        data: null
      }
    }
  },

  computed: {
    minimumWithdrawalAmount () {
      return minimumWithdrawalAmount
    },

    commissionFilterPeriods () {
      return commissionFilterPeriods
    }
  },

  async mounted () {
    await this.getAndSetCommissionStats()

    this.getUserBankAccountsForWithdrawalData()
    this.getMasterBanksForCreateUserBankAccountData()
  },

  methods: {
    ...mapActions('Affiliate', [
      'getCommissionSummaryCount', 'getCommissionHistory', 'getCommissionHistoryDetail', 'createWithdrawalAffiliate'
    ]),
    ...mapActions('BankAccount', ['getMasterBanks', 'getBankAccountsWithdrawal', 'createBankAccount']),

    generateRandomString,

    // Commission Stats
    handleCommissionFilterChange () {
      this.getAndSetCommissionStats()
    },

    async getAndSetCommissionStats () {
      await this.getCommissionStats()
      this.setCommissionStats()
    },

    async getCommissionStats () {
      try {
        this.getCommissionSummaryData.loading = true

        const response = await this.getCommissionSummaryCount({
          params: {
            period: this.commissionFilter.periodDate
          }
        })
        this.getCommissionSummaryData.data = response.data.data.commission_summary
      } catch (error) {
        console.log(error)

        this.$message({
          message: 'Gaga mendapatkan data statistik komisi',
          type: toastTypes.error.value,
          showClose: true
        })
      } finally {
        this.getCommissionSummaryData.loading = false
      }
    },

    setCommissionStats () {
      if (!isObjectNotEmptyOrNull(this.getCommissionSummaryData.data)) {
        return
      }

      const {
        commission_percentage: commissionPercentage,
        total_balance: totalBalance,
        order_statistic: orderStatistic,
        commission_statistic: commissionStatistic,
        withdrawal_statistic: withdrawalStatistic,
        period
      } = this.getCommissionSummaryData.data

      // Commission Percentage
      this.getCommissionSummaryData.commissionPercentage = commissionPercentage

      // Balance
      Object.assign(this.getCommissionSummaryData.commissionStats.balance, {
        amount: totalBalance
      })

      // orderCompleted
      Object.assign(this.getCommissionSummaryData.commissionStats.orderCompleted, {
        amount: orderStatistic.total_order,
        subtitle: period.label,
        percentage: `${orderStatistic.trend_percentage}`,
        trendStatus: orderStatistic.trend_status,
        infoText: this.commissionFilter.periodDate !== commissionFilterPeriods.all.value
          ? `Dari ${period.previous_label}`
          : ''
      })

      // totalCommission
      Object.assign(this.getCommissionSummaryData.commissionStats.totalCommission, {
        amount: commissionStatistic.total_commission,
        subtitle: period.label,
        percentage: `${commissionStatistic.trend_percentage}`,
        trendStatus: commissionStatistic.trend_status,
        infoText: this.commissionFilter.periodDate !== commissionFilterPeriods.all.value
          ? `Dari ${period.previous_label}`
          : ''
      })

      // withdrawalPending
      Object.assign(this.getCommissionSummaryData.commissionStats.withdrawalPending, {
        amount: withdrawalStatistic.total_nominal,
        subtitle: `(${withdrawalStatistic.total_count} Pengajuan)`
      })
    },

    // Commission Withdrawal
    openModalCreateBankAccount () {
      this.$refs.btnOpenModalCreateBankAccount.click()
    },

    handleModalCreateBankAccountClose () {
      if (Array.isArray(this.$refs.btnOpenModalCreateCommissionWithdrawSubmission)) {
        this.$refs.btnOpenModalCreateCommissionWithdrawSubmission[0].click()
      } else {
        this.$refs.btnOpenModalCreateCommissionWithdrawSubmission.click()
      }
    },

    async getUserBankAccountsForWithdrawalData () {
      try {
        const response = await this.getBankAccountsWithdrawal({
          params: {
            paginated: this.getUserBankAccountsData.paginated,
            ...this.getUserBankAccountsData.params
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.getUserBankAccountsData, response.data.data.accounts
        )
      } catch (error) {
        console.log(error)

        this.$message({
          message: 'Gagal mendapatkan data akun bank',
          type: toastTypes.error.value,
          showClose: true
        })
      }
    },

    async getMasterBanksForCreateUserBankAccountData () {
      try {
        const response = await this.getMasterBanks({
          data: {
            paginated: false
          }
        })

        assignResponseToDataModelDataNotPaginated(this.getMasterBanksData, response.data.data.banks)
      } catch (error) {
        console.log(error)

        this.$message({
          message: 'Gagal mendapatkan data master bank',
          type: toastTypes.error.value,
          showClose: true
        })
      }
    },

    async handleBtnSubmitCreateUserBankAccountClick (payload) {
      try {
        this.createUserBankAccountData.loading = true

        await this.createBankAccount({
          data: payload.data
        })

        this.$refs.modalCreateBankAccount.resetFormBankAccount()
        this.$refs.modalCreateBankAccount.closeModal()

        await this.getUserBankAccountsForWithdrawalData()
        this.$refs.modalCreateCommissionWithdrawSubmission.setFormWithdrawData({
          bankAccount: this.getUserBankAccountsData.data[0].id
        })
      } catch (error) {
        console.log(error)

        await this.$Swal.fire({
          ...defaultSwal2Options(),
          icon: 'error',
          title: 'Gagal Menambahkan Akun Bank',
          text: !isResponseInternalServerError(error)
            ? getResponseErrorMessage(error)
            : 'Gagal menambahkan akun bank. Mohon tunggu beberapa saat dan coba kembali.'
        })
      } finally {
        this.createUserBankAccountData.loading = false
      }
    },

    async handleBtnSubmitCreateCommissionWithdrawalClick (payload) {
      try {
        this.createCommissionWithdrawalData.loading = true

        await this.createWithdrawalAffiliate({
          data: payload.data
        })

        await this.$Swal.fire({
          ...defaultSwal2Options(),
          icon: 'success',
          title: 'Berhasil',
          text: 'Pengajuan penarikan komisi afiliasi telah berhasil. Pengajuan akan diproses oleh admin 4-14 hari kerja.'
        })
          .then(async (result) => {
            if (!result.isConfirmed) { return }
            this.$refs.modalCreateCommissionWithdrawSubmission.closeModal()
            await this.getAndSetCommissionStats()
          })
      } catch (error) {
        console.log(error)

        await this.$Swal.fire({
          ...defaultSwal2Options(),
          icon: 'error',
          title: 'Gagal Mengajukan Penarikan',
          text: 'Gagal mengajukan penarikan. Mohon tunggu beberapa saat dan coba kembali.',
          heightAuto: false,
          confirmButtonText: 'Kembali'
        })
      } finally {
        this.createCommissionWithdrawalData.loading = false
      }
    },

    // List Commission History
    resetListCommissionHistoriesData () {
      this.$refs.listCommissionHistoriesByMonth.completeInfiniteLoading()

      resetPaginationData(this.getCommissionHistoriesData)

      this.$refs.listCommissionHistoriesByMonth.resetInfiniteLoading()
    },

    handleFilterBalanceTypeChange (balanceType) {
      if (this.commissionHistoryFilter.balanceType === balanceType.value) {
        return
      }

      this.commissionHistoryFilter.balanceType = balanceType.value
      this.resetListCommissionHistoriesData()
    },

    async handleInfiniteLoadingCommissionHistories ($state) {
      if (this.getCommissionHistoriesData.page >= this.getCommissionHistoriesData.totalPage) {
        $state.complete()
        return
      }

      await this.getAffiliateCommissionHistories()
      $state.loaded()
    },

    async getAffiliateCommissionHistories () {
      try {
        this.getCommissionHistoriesData.loading = true

        const payload = {
          params: {
            paginated: this.getCommissionHistoriesData.paginated,
            per_page: this.getCommissionHistoriesData.perPage,
            page: this.getCommissionHistoriesData.page + 1
          }
        }

        if (this.commissionHistoryFilter.balanceType !== affiliateCommissionBalanceTypes.all.value) {
          payload.params.balance_type = this.commissionHistoryFilter.balanceType
        }

        const response = await this.getCommissionHistory(payload)
        assignResponseToDataModelData(
          this.getCommissionHistoriesData,
          response.data.data.affiliate_balance_histories,
          false
        )
      } catch (error) {
        console.log(error)

        this.$message({
          message: 'Gagal mendapatkan data riwayat komisi',
          type: toastTypes.error.value,
          showClose: true
        })

        this.$refs.listCommissionHistoriesByMonth.completeInfiniteLoading()
      } finally {
        this.getCommissionHistoriesData.loading = false
      }
    },

    async handleCommissionHistoryItemClick (commissionHistoryItem) {
      try {
        this.commissionHistoryDetail.balanceType = commissionHistoryItem.balance_type
        this.commissionHistoryDetail.data = null
        this.$refs.btnOpenCommissionHistoryDetail.click()
        this.commissionHistoryDetail.loading = true

        const response = await this.getCommissionHistoryDetail({
          id: commissionHistoryItem.id
        })
        this.commissionHistoryDetail.data = response.data.data.affiliate_balance_history

        await waitFor(700)
      } catch (error) {
        console.log(error)

        this.$message({
          message: 'Gagal mendapatkan detail riwayat komisi',
          type: toastTypes.error.value,
          showClose: true
        })
      } finally {
        this.commissionHistoryDetail.loading = false
      }
    }
  }
}
