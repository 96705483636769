import { render, staticRenderFns } from "./DetailProductSection.vue?vue&type=template&id=9041d4d6&scoped=true&"
import script from "./DetailProductSection.vue?vue&type=script&lang=js&"
export * from "./DetailProductSection.vue?vue&type=script&lang=js&"
import style0 from "./DetailProductSection.vue?vue&type=style&index=0&id=9041d4d6&lang=scss&scoped=true&"
import style1 from "./DetailProductSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9041d4d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductImagesFooter: require('/usr/src/app/components/home/products/detail/ProductImagesFooter.vue').default,Badge: require('/usr/src/app/components/base/Badge.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,PopupDetailVoucher: require('/usr/src/app/components/home/cart/PopupDetailVoucher.vue').default})
