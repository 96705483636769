import { render, staticRenderFns } from "./DashboardAffiliate.vue?vue&type=template&id=64f9ee03&scoped=true&"
import script from "./DashboardAffiliate.vue?vue&type=script&lang=js&"
export * from "./DashboardAffiliate.vue?vue&type=script&lang=js&"
import style0 from "./DashboardAffiliate.vue?vue&type=style&index=0&id=64f9ee03&lang=scss&scoped=true&"
import style1 from "./DashboardAffiliate.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f9ee03",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/usr/src/app/components/base/Alert.vue').default,CardCommissionStat: require('/usr/src/app/components/account/affiliate/commission/CardCommissionStat.vue').default,PopularProductsAffiliate: require('/usr/src/app/components/account/affiliate/dashboard/PopularProductsAffiliate.vue').default,ProductRecommendationAffiliate: require('/usr/src/app/components/account/affiliate/dashboard/ProductRecommendationAffiliate.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
