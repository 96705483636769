//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultPaginationAttributes } from '~/utils/helpers/pagination-helper'
import { generateRandomString } from '~/utils/helpers/string-helper'
import { cardProductPurposes } from '~/utils/helpers/product-helper'

export default {
  props: {
    unaffiliatedProductsData: {
      type: Object,
      default: () => ({
        ...defaultPaginationAttributes(),
        perPage: 8
      })
    }
  },

  data () {
    return {
      inputSearch: '',
      onLoading: false,
      perPage: 8,
      infiniteId: +new Date(),
      storeType: '',
      selectedProducts: []
    }
  },

  computed: {
    cardProductPurposes () {
      return cardProductPurposes
    }
  },

  methods: {
    generateRandomString,

    handleInfiniteLoadingListUnaffiliatedProducts ($state) {
      this.$emit('infinite-loading', $state)
    },

    handleAddProductToAffiliate (payload) {
      this.$emit('add-product-to-affiliate', payload)
    },

    completeInfiniteLoadingListUnaffiliatedProducts () {
      this.$refs.infiniteLoadingListUnaffiliatedProducts.stateChanger.complete()
    },

    resetInfiniteLoadingListUnaffiliatedProducts () {
      this.$refs.infiniteLoadingListUnaffiliatedProducts.stateChanger.reset()
    }
  }
}
