//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      navigationData: [
        {
          id: 0,
          icon: '/img/icon/bottom-navigation/new/icon-home.svg',
          iconActive: '/img/icon/bottom-navigation/icon-home-fill.svg',
          text: 'Beranda',
          isActive: false,
          link: '/',
          type: 'normal',
          loggedIn: true
        },
        {
          id: 1,
          icon: '/img/icon/bottom-navigation/new/icon-wishlist.svg',
          iconActive: '/img/icon/bottom-navigation/icon-wishlist-fill.svg',
          text: 'Wishlist',
          isActive: false,
          link: '/account/wishlist',
          type: 'wishlist',
          loggedIn: false
        },
        {
          id: 3,
          icon: '/img/icon/bottom-navigation/new/icon-cart.svg',
          iconActive: '/img/icon/bottom-navigation/icon-cart-fill.svg',
          text: 'Cart',
          isActive: false,
          link: '/cart',
          type: 'cart',
          loggedIn: true
        },
        {
          id: 4,
          icon: '/img/icon/bottom-navigation/new/icon-user.svg',
          text: 'Akun',
          isActive: false,
          accountNotif: 0,
          link: this.$auth.loggedIn ? '/account/profile' : `/login?redirect_from=${this.$route.fullPath}`,
          type: 'account',
          loggedIn: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Cart', ['getTotalQtyCart']),
    ...mapGetters('Product', ['getTotalQtyWishlist']),
    user () {
      if (this.$auth.user) {
        return this.$auth.user
      } else {
        return null
      }
    }
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Product', ['getProductWishlist']),
    initComponent () {
      if (this.$route.name !== 'wishlist' && this.$auth.loggedIn) {
        this.handleGetWishlist()
      }
      if (!this.$auth.loggedIn) {
        this.navigationData = this.navigationData.filter(
          data => data.loggedIn === true
        )
      }
    },
    async handleGetWishlist () {
      try {
        await this.getProductWishlist({
          data: { session_id: this.$cookies.get('session_id') }
        })
      } catch (error) {
        console.error(error)
      }
    },
    checkMenuActive (index) {
      return this.navigationData[index].link === this.$route.path
    }
  }
}
