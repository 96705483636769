//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      pusher: null,
      channel: null,
      configPusher: null,
      orderSuccess: false
    }
  },
  computed: {
    ...mapState('Order', ['order', 'onSuccess'])
  },
  mounted () {
    this.configPusher = {
      cluster: process.env.PUSHER_CLUSTER,
      forceTLS: true,
      encrypted: true,
      authEndpoint: `${process.env.BASE_API}/v1/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `${this.$auth.getToken('local')}`
        }
      }
    }
    this.handlePusherMessage()
  },
  beforeDestroy () {
    // unsub if pusher is subscribed
    if (this.pusher && this.orderSuccess) {
      this.pusher.unsubscribe(
        `private-user.${this.$store.state.auth.user.id}.create-order`
      )
      this.$pusher.disconnectPusher()
    }
  },
  methods: {
    ...mapMutations('Order', ['setOrderID']),
    handlePusherMessage () {
      if (this.pusher === null) {
        this.pusher = this.$pusher.getPusher(this.configPusher)
        this.channel = this.pusher.subscribe(
          `private-user.${this.$store.state.auth.user.id}.create-order`
        )
          this.channel.bind('order-created', (data) => {
          this.handleSuccess(data)
          this.pusher.unsubscribe(
            `private-user.${this.$store.state.auth.user.id}.create-order`
          )
        })
      }
    },
    handleSuccess (data) {
      if (this.order.order_payment.code === data.data.payment_detail.order_payment.code) {
        if (data.status_code === 200 || data.status_code === 201) {
          this.orderSuccess = true
          if (
            this.$refs.buttonClose !== undefined &&
            this.order.order_payment.code ===
              data.data.payment_detail.order_payment.code
          ) {
            if (localStorage.cartItem) {
              localStorage.removeItem('cartItem')
              localStorage.removeItem('cartPrize')
            }
            if (localStorage.note) {
              localStorage.removeItem('note')
            }
            if (localStorage.voucher) {
              localStorage.removeItem('voucher')
            }
            if (localStorage.usePoint) {
              localStorage.removeItem('usePoint')
            }
            this.$refs.buttonClose.click()
            if (
              data.data.payment_detail.payment_method.type.code ===
                'bank_transfer' ||
              data.data.payment_detail.payment_method.type.code ===
                'virtual_account'
            ) {
              window.location = `/payment-detail/${data.data.payment_detail.order_payment.id}`
            } else if (
              data.data.payment_detail.payment_method.type.code === 'credit_card'
            ) {
              this.$Swal
                .fire({
                  icon: 'success',
                  title: 'Pesanan berhasil dibuat!',
                  heightAuto: false
                })
                .then((confirm) => {
                  if (confirm) {
                    window.location = `/account/shop/my-order/detail/${data.data.orders[0].id}`
                  }
                })
            } else {
              this.setOrderID(data.data.payment_detail.order_payment.id)
              this.$refs.buttonShowPopupOtherPayment.click()
            }
          }
        } else if (this.$refs.buttonClose) {
          setTimeout(() => {
            this.$refs.buttonClose.click()
          }, 500)

          setTimeout(() => {
            this.$Swal
              .fire({
                icon: 'error',
                title: 'Tidak dapat memproses pesanan',
                text: data.message,
                heightAuto: false,
                confirmButtonText: 'Kembali'
              })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = '/cart'
                }
              })
          }, 500)
        }
      }
    }
  }
}
