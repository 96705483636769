import { render, staticRenderFns } from "./ListCommissionHistoriesByMonth.vue?vue&type=template&id=4378be63&scoped=true&"
import script from "./ListCommissionHistoriesByMonth.vue?vue&type=script&lang=js&"
export * from "./ListCommissionHistoriesByMonth.vue?vue&type=script&lang=js&"
import style0 from "./ListCommissionHistoriesByMonth.vue?vue&type=style&index=0&id=4378be63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4378be63",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/usr/src/app/components/base/Tab.vue').default,CommissionHistoryItem: require('/usr/src/app/components/account/affiliate/commission/CommissionHistoryItem.vue').default,EmptyState: require('/usr/src/app/components/base/EmptyState.vue').default})
